.post-grid__card{
    height: 100%;
    display: flex;
    flex-direction: column;
    
}
.post-grid__card-content-container{
    padding:0.75rem;
}
.post-grid__card-content{
   
    font-size: 1.1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
            line-clamp: 2; 
    -webkit-box-orient: vertical;
  
}

h3.post-grid__card-title{
  
    margin:0;
    line-height: 1.35;
    color:#006400;
    font-size: 1.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
            line-clamp: 2; 
    -webkit-box-orient: vertical;
  
}
.post-grid__card-date{
    background-color: #006400;
    border-radius: 4px;
    color: #fff;
    font-size: .8em;
    padding: 0.25rem 0.5rem;
    z-index: 5;
}

.post-grid__card-info{
    font-size: 0.8rem;
  
}


.post-grid__card-footer{
    padding:0.75rem;
    display:flex;
    justify-content:space-between;
}
.post-grid__card-footer a{
    color:#006400;
    font-weight:bold;
    text-decoration:none;
    
}
.post-grid__card-footer a:hover{
    text-decoration:underline;
    transition:0.2s;

}

