.page-404{
    display: flex;
    flex-direction: column;
    width:90%;
    max-width: 960px;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin:auto;
}

.page-404 > p{
    width:95%;
    max-width:480px;
}
.page-404 > img{
    width: 80%;
    max-width:380px;
    padding:2rem 1rem 3rem;
}
