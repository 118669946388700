
.sponsor-grid__card-content-container{
    padding:0.5rem 1rem;

}
.sponsor-grid__card-info{
    margin:0;
}
.sponsor-grid__card-title{
    color:#006400;
    overflow: hidden;
    text-overflow: ellipsis;
    margin:0.75rem  0;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
            line-clamp: 1; 
    -webkit-box-orient: vertical;
}

.sponsor-grid__card-link{
    
    color:#13120B;
    text-decoration: none;
}
.sponsor-grid__card-link::before {
    content: "→ ";
}
