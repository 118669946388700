.card {
  
  background-color: #fff;
  border-radius: 8px;
  text-align: left;
  -webkit-box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.1); 
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.1);
}

.form-card{
  height: 100%;
  padding:2rem 1rem;
  
  
}
.form-card form{
  width:90%;
  margin:0 auto;
}

.borderless{
  border-radius: 0;
}