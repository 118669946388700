.nav-links {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nav-links li {
  margin: 1rem;
}

.nav-links a {
  border-radius: 8px;
  border: 1px solid transparent;
  display:flex;
  align-items: center;
  color:  #006400;
  text-decoration: none;
  padding: 0.5rem;
}

.nav-links a:hover,
.nav-links a:active,
.nav-links a.active {
  background: #ffffff;
  border: 1px solid #111;
  color: #111;
}


.show-on-mobile{
  display:block;
}


@media (min-width: 768px) {
  .nav-links {
    flex-direction: row;
    justify-content: left;

  }
  .show-on-mobile{
    display:none;
  }
  .nav-links li {
    margin: 1rem;
  }

  .nav-links a {
    color:  #006400;
    text-decoration: none;
    font-size: 1.2rem;
  }
  
}
