
.authentication {
  width: 90%;
  max-width:600px;
  height:50%;
  margin: 7rem auto;
  text-align: left;
  padding:1rem;
}

