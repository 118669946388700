
main{
    min-height: 100vh;
    display: flex;
}

.main-container{
    margin-top:10rem;
}

.sr-only{
    position:absolute;
left:-10000px;
top:auto;
width:1px;
height:1px;
overflow:hidden;
}

.cookies__modal{
  margin-top: 2rem;
}
::-webkit-scrollbar {

    width: 3px;
    height:3px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #006400;
    border-radius: 3px;
  }