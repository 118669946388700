.live-ticker-item a{
    color:#fff;
    text-decoration: none;
    width:100%;
    max-width:100vw;
    text-align: center;
    flex:1;
    margin:auto 0;
    display:block;
}
.live-ticker-item{
    padding:0.5rem 0;
    background-color: #006400;
    display:flex;
    align-items: center;
}
.live-ticker-container{
    width: 100%;
    max-width:100vw;
    background-color: #006400;
}

@media(min-width: 789px){
    .live-ticker-item a{
        font-size: 1rem;
    }
}