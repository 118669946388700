.button {
  font: inherit;
  padding: 0.5rem 0.75rem;
  border: 1px solid #006400;
  border-radius: 6px;
  background:  #006400;
  color: #fff;
  box-shadow: 1px 1px 2px #052e1438;
  cursor: pointer;
  margin-right: 0.5rem;
 
  text-decoration: none;
  display: inline-block;
  transition: 0.2s;
}

.button:focus {
  outline: none;
}
a.dl-button:hover{
  text-decoration:none
}

.button:hover,
.button:active {
  background: transparent;
  transform: scale(90%);
  transition: 0.2s;
  color: #006400;
}

.button--inverse {
  background: transparent;
  color: #006400;
}

.button--inverse:hover,
.button--inverse:active {
  color: white;
  background: #006400;
}

.button--danger {
  background: #AB6B01;
  border-color: #AB6B01;
}

.button--danger:hover,
.button--danger:active {
  background: #006400;
  border-color: #006400;
}

.button:disabled,
.button:hover:disabled,
.button:active:disabled {
  background: #ccc;
  color: #979797;
  border-color: #ccc;
  cursor: not-allowed;
}

.button--small {
  font-size: 0.85rem;
  padding:0.25rem 0.5rem;
}

.button--big {
  font-size: 1.5rem;
}

.button--icon{
  display:flex;  
  align-items: center;
}
.button--icon svg{
  margin-right:0.2rem;
}