
.team{
    margin: 3rem 0;
    width:100%;
    max-width: 1200px;

}
.featured-sponsors__container{
    padding: 0 1rem;
}


@media (min-width: 768px) {
    .team{
    margin:10rem auto;
    }
    .team div.featured-posts__container{
        padding:0;
    }
    .featured-sponsors__container{
        padding: 0;
    }
}