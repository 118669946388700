.admin-nav-links {
    list-style: none;
    margin: auto;
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .admin-nav-links li {
    text-align: left;
    display: flex;
    border-top:1px solid #fff;


  }
  
  .admin-nav-links a {
    
    border: 1px solid transparent;
  
    color:  #111;
    text-decoration: none;
   flex:1;
    padding:1rem;
  }
  
  .admin-nav-links a:hover,
  .admin-nav-links a:active,
  .admin-nav-links a.active
   {
    color: #ffffff;
    background:rgb(40, 204, 100);

    
  }
 .bar-control{
   position:absolute;
   bottom:1rem;

 }
  
 