

.post-grid__card-short h2{
    font-size: 1.5rem;
    line-height: 1.35;
    margin:0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
            line-clamp: 3; 
    -webkit-box-orient: vertical;
}

.post-grid__card-short a{
    text-decoration: none;
    font-weight: bold;
    color:#006400;
    font-size: 0.9rem;
}
.post-grid__card-short a:hover{
    text-decoration:underline;
    transition: 0.2s;
}