a.contact-link{
    color:#006400;
    text-decoration: none;
}

.kontakt a:hover{
    text-decoration:underline;
}

.kontakt{
    width: 90%;
    max-width: 1200px;
    margin:10rem auto;
}