.modal {
  z-index: 100;
  position: fixed;
  top: 2rem;
  left: 2.5%;
  width: 95%;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 5px;
}
.cookies__modal{

}
.modal__header {
  width: 100%;
  padding: 1rem 0;
  background: #006400;
  border-radius: 6px 6px 0 0;
}

.modal__header h2 {
  margin: 1rem;
  color: white;

}

.modal__content {
  padding: 1rem;

}

.modal__footer {
  padding: 1rem
}

@media (min-width: 768px) {
  .modal {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}

.modal-enter {
  transform: translateY(-10rem);
  opacity: 0;
}

.modal-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: all 200ms;
}

.modal-exit {
  transform: translateY(0);
  opacity: 1;
}

.modal-exit-active {
  transform: translateY(-10rem);
  opacity: 0;
  transition: all 200ms;
}
