.top-slider{
width: 100%;
}



.top-slider .slick-dots {
	display: flex;
	justify-content: center;
	margin: 0;
	padding: 0.45rem 0;
	list-style-type: none;
	bottom:0
}

	.slick-dots	li {
			margin: 0 0.15rem;
	}
	
	.slick-dots li button {
			display: block;
			width: 0.65rem;
			height: 0.65rem;
			padding: 0;
			border: none;
			border-radius: 100%;
			background-color: hsl(120, 80%, 40%);
			text-indent: -9999px;
		}
	
		li.slick-active button {
			background-color: #006400;
		}
	
