.main-navigation__menu-btn {
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  cursor: pointer;
  flex:1;
}

.main-navigation__menu-btn span {
  display: block;
  width: 2rem;
  height: 2.8px;
  margin-left: 0.5rem;
  border-radius: 80px;
  background: #006400;
}

.main-navigation_header-logo{
}
.main-navigation_header-logo a{
}
.main-navigation__header-nav {
  display: none;
}
.main-navigation__header-icon-nav {
  flex:1;
}

.main-navigation__drawer-nav {
  height: 100%;
}

@media (min-width: 768px) {
  .main-navigation__menu-btn {
    display: none;
  }

  .main-navigation__header-nav {
    display: block;
    flex:1
  }
  
  
}