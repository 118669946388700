.home-page{
width:100%;
max-width: 100vw;
margin-top:3.5rem;
display:flex;
flex-direction: column;
position:relative;
}
.home-page > div{
    flex:1;
}

.home-content{
    width:90%;
    max-width:1200px;
    margin: 0 auto;
    position: relative;
    height:100%;
    padding:1rem 0;
}
.home-page i.bg{
    display: none;
}

.home-content__paragraph{
    width:100%;
    margin:2rem 0;
}
.icon-buttons-container{
    display:flex;
}

.home-img{
    width:100%;
    max-width:600px;
}

.app-cta{
    margin:4rem auto;
    display:flex;
    flex-direction: column-reverse;
}

.app-cta div{
    flex:1;
    
}
@media  (min-width: 768px){

    .app-cta{
        margin:4rem auto;
        display:flex;
        flex-direction: row;
    }
    .home-content__paragraph{
        width:74%;
        
    }
}