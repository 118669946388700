html{
  scroll-behavior: smooth;


}
body {
  margin: 0;
  margin: 0 auto;


  background-color:  #f1f3f6;

  font-family: 'Fira Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h2{
  font-weight: bolder;
  color:#006400;
}
p{
  font-size: 1.1rem;
}
h1{
  color:#006400;
  font-size: 3rem;
}
h3{
  font-weight: bolder;
}
hr.djk-hr{
  height:2px;
  width:150px;
  background-color: #FFD501;
  border:0;
  border-radius: 8px;
 margin-left: 0;
}

b{}