select{
    height:3.25rem;
    display: block;
    width:93.5%;
    border-radius: 8px;
    font: "Dosis", sans-serif;
    border: 2px solid rgba(252, 250, 241, 0.15);
    background: #f1f1f1;
    padding: 0.5rem;

  
}