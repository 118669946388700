.image-upload.center {
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .image-upload__preview {
   
    width: 8rem;
    height: 8rem;
    border: 2px solid #013936;
    border-radius: 5000px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 1rem;
    
  }
  
  .image-upload__preview img {
    border-radius: 5000px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }