.sidebar {
    position: fixed;
    right: 0;
    top: 0;
    height: 100vh;
    width: 240px;
    background:  #fff;
    z-index: 9980;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
}
  
