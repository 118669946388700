.news-page{
    width:100%;
    max-width: 100vw;
    display:flex;
    flex-direction: column;
    position:relative;

}

.news-page__container{
    width:100%;
    max-width: 1200px;
    margin:4rem auto;
    position:relative;
}
.news-info__container{
    display:flex;
    flex-direction: column;
}
.news-info__container > div{
    flex:1;
    padding:0.5rem 1rem;
}
.news-info__container div img{
    display: block;
    margin:4rem auto;
    width: 50%;
    animation: App-logo-spin infinite 20s linear;

}


@media(min-width: 768px){
    .news-info__container{
        flex-direction: row;
    }
}


@keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }