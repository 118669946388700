.react-calendar {
  border:2px solid #006400 !important;
  border-radius: 4px;
  width: 100% !important;;
}
.calendar-widget{
    position: fixed;
    top: 4.5rem;
    right:1rem;
    width:85%;
    max-width:420px;
    padding:1rem;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
    display:flex;
    flex-direction: column;

}
.calendar-widget span.close-cal{
 margin: 0 0 0.5rem auto;
 font-weight: bold;
 padding:0.2rem 0.5rem;
 border:2px solid #006400;
 border-radius: 4px;
 color:#006400;
 cursor: pointer;

}
.calendar-ul{
  height: 200px;
  overflow-y: scroll;
}

span.close-cal:hover{
  background-color:#006400;
  color:#fff;
  transition: 0.2s;
}

.react-calendar__tile--now abbr{
  color: #FFD501;
}
.hasDate abbr{
  color: #006400;
}
.react-calendar__tile:disabled{
  background-color: #fff !important;
  opacity: 0.25;
}
.react-calendar__tile--active{
  background: #006400 !important;
  color:#fff !important;
}

.calendar-widget ul{
 
  padding:0;
}
.calendar-widget ul li{
  padding:0.25rem 0.5rem;
}



.calendar-enter {
    opacity: 0;
    transform: scale(0.9);
  }
.calendar-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }
.calendar-exit {
    opacity: 1;
  }
.calendar-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }
  