#Mannschaften a{
    margin:auto;
    text-decoration: none;
    color: #fff;
    font-size: 3rem;
    font-weight: bold;
}

#Mannschaften div.halfwidth div{
    background-color: #006400;
    height:200px;    
    display:flex;
    border-radius: 8px;
    margin:0.5rem;

}

#Mannschaften div.halfwidth div:last-child{
    background-color: #00640080;
}

#Mannschaften{
    width:90%;
    margin:10rem auto;
    max-width: 1200px;
}

#Mannschaften div.halfwidth{
    
}