.icon-nav-links {
    list-style: none;
    margin: 0;
    padding: 0;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
  .icon-nav-links li {
}
  .nav-calendar{
    font-size:1.2em;
    cursor: pointer;
  }
  .icon-nav-links a, .nav-calendar {
    border-radius: 6px;
    border: 1px solid transparent;
    padding: 0.25rem;
    color:  #006400;
    text-decoration: none;
  }
  .nav-calendar:hover,
  .nav-calendar:active,
  .icon-nav-links a:hover,
  .icon-nav-links a:active,
  .icon-nav-links a.active {
    background: #ffffff;
    color: #111;
  }

  
  .icon-nav-links button {
    cursor: pointer;
    border-radius: 6px;
    background: transparent;
    font: inherit;
  }
  
  .icon-nav-links button:focus {
    outline: none;

  }
  

  .icon-nav-links button {
    border: 1px solid #fff;
    background: transparent;
  }
  
  .icon-nav-links button:hover,
  .icon-nav-links button:active {
    background: #fff;
    color: #01393683;
    border: 1px solid #fff;
    transition: 0.8s;
    box-shadow: inset 0 2px 6px rgba(0, 0, 0, 0.05);

  }
  .admin-navigation__drawer-nav{
    margin-top:5rem;
  }

  .icon-nav-links li {
    margin:1rem;
  }
  .hide-on-mobile{
    display: none;
  }
  @media (min-width: 768px) {
    .icon-nav-links {
      flex-direction: row;
    }
  
    .icon-nav-links li {
      margin: 1rem;
    }
    .hide-on-mobile{
      display: flex;
    }
  
    .icon-nav-links a {
      color: #006400;
      text-decoration: none;
      font-size: 1.2rem;
    }
  
    
  }
  