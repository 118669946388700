.side-drawer {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  height: 100vh;
  width: 70%;
  background:  #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
}

.drawer-enter {
  opacity: 0;
  transform:translateX(100%);
}
.drawer-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 200ms, transform 200ms;
}
.drawer-exit {
  opacity: 1;
}
.drawer-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 200ms, transform 200ms;
}
