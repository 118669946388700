h3.team-grid__card-title{
    color:#006400;
    
    font-size: 1.5rem;
    margin:0;
    overflow: hidden;
    text-overflow: ellipsis;
}

.team-grid__card-info{
    font-size: 0.8rem;
    overflow: hidden;
    text-overflow: ellipsis;
   
    
}

.team-grid__card-content{
    display:block;
    font-size: 1.1rem;
    display:flex;
    line-height: 1.5;
    margin:0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
            line-clamp: 2; 
    -webkit-box-orient: vertical;
}

.team-grid__card-footer{
    margin-top:1rem;
    flex:1;
    align-items:center;
    display:flex;
    justify-content:space-between;
}
.team-grid__card-footer a{
    color:#006400;
    font-weight:bold;
    text-decoration:none;
    
}
.team-grid__card-footer a:hover{
    text-decoration:underline;
    transition:0.2s;
 
}
.team-grid__card-container{
    padding:0.75rem;
}

