.lead-slider__item{
    margin:0.75rem 0;
}
.lead-slider__card{
    position: relative;
    flex-direction: column;
    justify-content:space-between;
    cursor: pointer;
}
.lead-slider__card-title{
    margin:0.5rem 0;
   
    color:#006400;
}
.lead-slider__card-img{
    padding-top:100%;
}

.lead-slider__card-content{
    margin:0;
    font-size: 0.8rem;
    font-weight: bold;
    width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
            line-clamp: 1; 
    -webkit-box-orient: vertical;
}

.lead-slider__card-content-container{
    padding:0.5rem 1rem;
    margin-top:0.5rem;

}
@media(min-width: 768px) {
    .lead-slider__card{
        display:flex;
        margin:auto;
        width:95%;
        flex-direction: column;
    }
}