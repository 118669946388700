.main-container{
    margin:10rem auto 0;
    width:100%;
    max-width: 1200px;
}

.dash-container{
    margin: 10rem auto 5rem;
    width:100%;
    max-width: 980px;
    display:flex;
    
}

.dash-container > div{
    margin:0 auto;
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    width:95%;
    max-width:980px;
}
   