.slider-item {
  width:100%;
  max-width:100vw;
  height: calc(100vh - 5.65rem);
}
.post-slider__item-headline{
  font-size: 2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
          line-clamp: 2; 
  -webkit-box-orient: vertical;
  margin:0
}
.post-slider__item-text{
    display:block;
    font-size: 1.1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
            line-clamp: 3; 
    -webkit-box-orient: vertical;
 
}
.slick-arrow{
  background-color:#006400;
  display: none !important;;
}
