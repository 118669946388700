.post-item__header img{
    width:100%;
    max-width: 1200px;
    border-radius:8px;
}

.post-content__paragraph{
    white-space: pre-wrap;
}

.post-item_content{
    padding:0 1rem;
    display:flex;
    flex-direction: column-reverse;
}

.post-item_content div{
    flex:1;
}

.post-item_content div:first-child{
    flex:3;
}
.post-item__gallery-container{
    padding:1rem;
}
.report-card{
    padding:0.5rem 1rem;
}

.report-stats{
   display:flex;
   align-items: center;
   justify-content: center;
   text-align: center;
}
.report-stats div{
    flex:1;
    max-width: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
    
}

.report-stats__count div p{
    margin: 0;
    padding: 0;

}
.report-stats__count div h2{
    margin: 0;
    padding: 0;
}

.logo-container{
     padding:1rem 0;
     height:60px;
}

.logo-container img{
    height:100%;
    max-height: 50px;
}

.gallery-image{
    border-radius:8px;
}

@media (min-width: 768px) {
   .post-item_content{
       flex-direction: row;
   }
}
